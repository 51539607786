import { squareDist, pointLineSquareDist, itemIntersectByLine, getPointsCenter, fractionToLine, isPointsOverlap, pointRectSquareDist, Line, isPointInPolygon } from '../../util/math';
var defaultOps = {
  maxRoutingIterations: 100,
  maxMarchingIterations: 100,
  pixelGroupSize: 2,
  edgeR0: 10,
  edgeR1: 10,
  nodeR0: 5,
  nodeR1: 10,
  morphBuffer: 5,
  threshold: 0.001,
  skip: 16,
  nodeInfluenceFactor: 1,
  edgeInfluenceFactor: 1,
  negativeNodeInfluenceFactor: -0.5
};
/**
 * Marching square algorithm for traching the contour of a pixel group
 * https://www.emanueleferonato.com/2013/03/01/using-marching-squares-algorithm-to-trace-the-contour-of-an-image/
 * @param potentialArea
 * @param threshold
 */
function MarchingSquares(contour, potentialArea, threshold) {
  var marched = false;
  var getVal = function getVal(x, y) {
    return potentialArea.cells[x + y * potentialArea.width];
  };
  var getState = function getState(x, y) {
    var squareVal = 0;
    if (getVal(x - 1, y - 1) >= threshold) {
      squareVal += 1;
    }
    if (getVal(x, y - 1) > threshold) {
      squareVal += 2;
    }
    if (getVal(x - 1, y) > threshold) {
      squareVal += 4;
    }
    if (getVal(x, y) > threshold) {
      squareVal += 8;
    }
    return squareVal;
  };
  var doMarch = function doMarch(xPos, yPos) {
    var x = xPos;
    var y = yPos;
    var prevX;
    var prevY;
    for (var i = 0; i < potentialArea.width * potentialArea.height; i++) {
      prevX = x;
      prevY = y;
      if (contour.findIndex(function (item) {
        return item.x === x && item.y === y;
      }) > -1) {
        if (contour[0].x !== x || contour[0].y !== y) {
          // encountered a loop but haven't returned to start: change direction using conditionals and continue back to start
        } else {
          return true;
        }
      } else {
        contour.push({
          x: x,
          y: y
        });
      }
      var state = getState(x, y);
      // assign the move direction according to state of the square
      switch (state) {
        case -1:
          console.warn('Marched out of bounds');
          return true;
        case 0:
        case 3:
        case 2:
        case 7:
          x++; // go right
          break;
        case 12:
        case 14:
        case 4:
          x--; // go left
          break;
        case 6:
          // go left if come from up else go right
          if (prevX === 0) {
            if (prevY === -1) {
              x -= 1;
            } else {
              x += 1;
            }
          }
          break;
        case 1:
        case 13:
        case 5:
          y--; // go up
          break;
        case 9:
          // go up if come from right else go down
          if (prevX === 1) {
            if (prevY === 0) {
              y -= 1;
            } else {
              y += 1;
            }
          }
          break;
        case 10:
        case 8:
        case 11:
          y++; // go down
          break;
        default:
          console.warn("Marching squares invalid state: ".concat(state));
          return true;
      }
    }
  };
  this.march = function () {
    for (var x = 0; x < potentialArea.width && !marched; x += 1) {
      for (var y = 0; y < potentialArea.height && !marched; y += 1) {
        if (getVal(x, y) > threshold && getState(x, y) !== 15) {
          marched = doMarch(x, y);
        }
      }
    }
    return marched;
  };
}
/**
 * Space partition & assign value to each cell
 * @param points
 */
var initGridCells = function initGridCells(width, height, pixelGroupSize) {
  var scaleWidth = Math.ceil(width / pixelGroupSize);
  var scaleHeight = Math.ceil(height / pixelGroupSize);
  var gridCells = new Float32Array(Math.max(0, scaleWidth * scaleHeight)).fill(0);
  return {
    cells: gridCells,
    width: scaleWidth,
    height: scaleHeight
  };
};
/**
 * Find the optimal already visited member to item;
   Optimal: minimize cost(j) = distance(i,j) ∗ countObstacles(i,j)
 * @param item
 * @param visited
 */
var pickBestNeighbor = function pickBestNeighbor(item, visited, nonMembers) {
  var closestNeighbour = null;
  var minCost = Number.POSITIVE_INFINITY;
  visited.forEach(function (neighbourItem) {
    var itemP = {
      x: item.getModel().x,
      y: item.getModel().y
    };
    var neighbourItemP = {
      x: neighbourItem.getModel().x,
      y: neighbourItem.getModel().y
    };
    var dist = squareDist(itemP, neighbourItemP);
    var directLine = new Line(itemP.x, itemP.y, neighbourItemP.x, neighbourItemP.y);
    var numberObstacles = nonMembers.reduce(function (count, _item) {
      if (fractionToLine(_item, directLine) > 0) {
        return count + 1;
      }
      return count;
    }, 0);
    if (dist * Math.pow(numberObstacles + 1, 2) < minCost) {
      closestNeighbour = neighbourItem;
      minCost = dist * Math.pow(numberObstacles + 1, 2);
    }
  });
  return closestNeighbour;
};
/**
 * 返回和线相交的item中，离边的起点最近的item
 * @param items
 * @param line
 */
var getIntersectItem = function getIntersectItem(items, line) {
  var minDistance = Number.POSITIVE_INFINITY;
  var closestItem = null;
  items.forEach(function (item) {
    var distance = fractionToLine(item, line);
    // find closest intersection
    if (distance >= 0 && distance < minDistance) {
      closestItem = item;
      minDistance = distance;
    }
  });
  return closestItem;
};
/**
 * Modify the directLine and Route virtual edges around obstacles
 */
var computeRoute = function computeRoute(directLine, nonMembers, maxRoutingIterations, morphBuffer) {
  var checkedLines = [];
  var linesToCheck = [];
  linesToCheck.push(directLine);
  var hasIntersection = true;
  var iterations = 0;
  var pointExists = function pointExists(point, lines) {
    var flag = false;
    lines.forEach(function (line) {
      if (flag) return;
      if (isPointsOverlap(point, {
        x: line.x1,
        y: line.y1
      }) || isPointsOverlap(point, {
        x: line.x2,
        y: line.y2
      })) {
        flag = true;
      }
    });
    return flag;
  };
  var isPointInNonMembers = function isPointInNonMembers(point, _nonMembers) {
    for (var _i = 0, _nonMembers_1 = _nonMembers; _i < _nonMembers_1.length; _i++) {
      var item = _nonMembers_1[_i];
      var bbox = item.getBBox();
      var itemContour = [[bbox.x, bbox.y], [bbox.x + bbox.width, bbox.y], [bbox.x, bbox.y + bbox.height], [bbox.x + bbox.width, bbox.y + bbox.height]];
      if (isPointInPolygon(itemContour, point.x, point.y)) {
        return true;
      }
    }
    return false;
  };
  // outer loop end when no more intersections or out of iterations
  while (hasIntersection && iterations < maxRoutingIterations) {
    hasIntersection = false;
    var _loop_1 = function _loop_1() {
      var line = linesToCheck.pop();
      var closestItem = getIntersectItem(nonMembers, line);
      if (closestItem) {
        var _a = itemIntersectByLine(closestItem, line),
          intersections_1 = _a[0],
          countIntersections = _a[1];
        // if line passes through item
        if (countIntersections === 2) {
          var testReroute = function testReroute(isFirst) {
            var tempMorphBuffer = morphBuffer;
            var virtualNode = rerouteLine(closestItem, tempMorphBuffer, intersections_1, isFirst);
            // test the virtualNode already exists
            var exist = pointExists(virtualNode, linesToCheck) || pointExists(virtualNode, checkedLines);
            var pointInside = isPointInNonMembers(virtualNode, nonMembers);
            while (!exist && pointInside && tempMorphBuffer >= 1) {
              // try a smaller buffer
              tempMorphBuffer /= 1.5;
              virtualNode = rerouteLine(closestItem, tempMorphBuffer, intersections_1, isFirst);
              exist = pointExists(virtualNode, linesToCheck) || pointExists(virtualNode, checkedLines);
              pointInside = isPointInNonMembers(virtualNode, nonMembers);
            }
            // 第二次route时不要求pointInside
            if (virtualNode && !exist && (!isFirst || !pointInside)) {
              // add 2 rerouted lines to check
              linesToCheck.push(new Line(line.x1, line.y1, virtualNode.x, virtualNode.y));
              linesToCheck.push(new Line(virtualNode.x, virtualNode.y, line.x2, line.y2));
              hasIntersection = true;
            }
          };
          testReroute(true);
          if (!hasIntersection) {
            // if we didn't find a valid point around the first corner, try the second
            testReroute(false);
          }
        }
      }
      // no intersection found, mark this line as completed
      if (!hasIntersection) {
        checkedLines.push(line);
      }
      iterations += 1;
    };
    // inner loop end when out of lines or found an intersection
    while (!hasIntersection && linesToCheck.length) {
      _loop_1();
    }
  }
  // 加入剩余的线
  while (linesToCheck.length) {
    checkedLines.push(linesToCheck.pop());
  }
  return checkedLines;
};
/**
 *  Connect item with visited members using direct line or virtual edges
 */
function getRoute(item, nonMembers, visited, maxRoutingIterations, morphBuffer) {
  var optimalNeighbor = pickBestNeighbor(item, visited, nonMembers);
  if (optimalNeighbor === null) {
    return [];
  }
  //  merge the consecutive lines
  var mergeLines = function mergeLines(checkedLines) {
    var finalRoute = [];
    while (checkedLines.length > 0) {
      var line1 = checkedLines.pop();
      if (checkedLines.length === 0) {
        finalRoute.push(line1);
        break;
      }
      var line2 = checkedLines.pop();
      var mergeLine = new Line(line1.x1, line1.y1, line2.x2, line2.y2);
      var closestItem = getIntersectItem(nonMembers, mergeLine);
      // merge most recent line and previous line
      if (!closestItem) {
        checkedLines.push(mergeLine);
      } else {
        finalRoute.push(line1);
        checkedLines.push(line2);
      }
    }
    return finalRoute;
  };
  var directLine = new Line(item.getModel().x, item.getModel().y, optimalNeighbor.getModel().x, optimalNeighbor.getModel().y);
  var checkedLines = computeRoute(directLine, nonMembers, maxRoutingIterations, morphBuffer);
  var finalRoute = mergeLines(checkedLines);
  return finalRoute;
}
/**
 * Calculate the countor that includes the  selected items and exclues the non-selected items
 * @param graph
 * @param members
 * @param nonMembers
 * @param options
 */
export var genBubbleSet = function genBubbleSet(members, nonMembers, ops) {
  // eslint-disable-next-line no-redeclare
  var options = Object.assign(defaultOps, ops);
  var centroid = getPointsCenter(members.map(function (item) {
    return {
      x: item.getModel().x,
      y: item.getModel().y
    };
  }));
  // 按照到中心距离远近排序
  members = members.sort(function (a, b) {
    return squareDist({
      x: a.getModel().x,
      y: a.getModel().y
    }, centroid) - squareDist({
      x: b.getModel().x,
      y: b.getModel().y
    }, centroid);
  });
  var visited = [];
  var virtualEdges = [];
  members.forEach(function (item) {
    var lines = getRoute(item, nonMembers, visited, options.maxRoutingIterations, options.morphBuffer);
    lines.forEach(function (l) {
      virtualEdges.push(l);
    });
    visited.push(item);
  });
  // 由于edge也可以作为member和nonMember传入，暂时不考虑把edges作为参数传入genBubbleSet
  // edges && edges.forEach(e => {
  //   virtualEdges.push(new Line(e.getSource().getModel().x, e.getSource().getModel().y, e.getTarget().getModel().x, e.getTarget().getModel().y));
  // });
  var activeRegion = getActiveRregion(members, virtualEdges, options.nodeR0);
  var potentialArea = initGridCells(activeRegion.width, activeRegion.height, options.pixelGroupSize);
  // Use march squares to generate contour
  var contour = [];
  var hull = [];
  for (var iterations = 0; iterations < options.maxMarchingIterations; iterations++) {
    fillPotentialArea(members, nonMembers, virtualEdges, activeRegion, potentialArea, options);
    contour = [];
    hull = [];
    if (!new MarchingSquares(contour, potentialArea, options.threshold).march()) continue;
    var marchedPath = contour.map(function (point) {
      return {
        x: Math.round(point.x * options.pixelGroupSize + activeRegion.minX),
        y: Math.round(point.y * options.pixelGroupSize + activeRegion.minY)
      };
    });
    // const marchedPath = marchingSquares(potentialArea, options.threshold).map(point => ({ x: Math.round(point.x * options.pixelGroupSize + activeRegion.minX), y: Math.round(point.y * options.pixelGroupSize + activeRegion.minY) }))
    if (marchedPath) {
      var size = marchedPath.length;
      if (options.skip > 1) {
        size = Math.floor(marchedPath.length / options.skip);
        // if we reduced too much (fewer than three points in reduced surface) reduce skip and try again
        while (size < 3 && options.skip > 1) {
          options.skip -= 1;
          size = Math.floor(marchedPath.length / options.skip);
        }
      }
      // copy hull values
      for (var i = 0, j = 0; j < size; j += 1, i += options.skip) {
        hull.push({
          x: marchedPath[i].x,
          y: marchedPath[i].y
        });
      }
    }
    var isContourValid = function isContourValid() {
      for (var _i = 0, members_1 = members; _i < members_1.length; _i++) {
        var item = members_1[_i];
        var hullPoints = hull.map(function (point) {
          return [point.x, point.y];
        });
        if (!isPointInPolygon(hullPoints, item.getBBox().centerX, item.getBBox().centerY)) return false;
      }
      // 不强制要求所有nonMembers都没有包含在内
      // for (const item of nonMembers) {
      //   if (isPointInPolygon({ x: item.getBBox().centerX, y: item.getBBox().centerY }, contour)) return false
      // }
      return true;
    };
    if (hull && isContourValid()) {
      return hull;
    }
    // update parameters for next iteraction
    options.threshold *= 0.9;
    if (iterations <= options.maxMarchingIterations * 0.5) {
      options.memberInfluenceFactor *= 1.2;
      options.edgeInfluenceFactor *= 1.2;
    } else if (options.nonMemberInfluenceFactor !== 0 && nonMembers.length > 0) {
      // after half the iterations, start increasing positive energy and lowering the threshold
      options.nonMemberInfluenceFactor *= 0.8;
    } else {
      break;
    }
  }
  return hull;
};
/**
 * unionboundingbox
 * @param members
 * @param edges
 */
function getActiveRregion(members, edges, offset) {
  var activeRegion = {
    minX: Number.POSITIVE_INFINITY,
    minY: Number.POSITIVE_INFINITY,
    maxX: Number.NEGATIVE_INFINITY,
    maxY: Number.NEGATIVE_INFINITY,
    width: 0,
    height: 0,
    x: 0,
    y: 0
  };
  var bboxes = [];
  members.forEach(function (item) {
    bboxes.push(item.getBBox());
  });
  edges.forEach(function (l) {
    bboxes.push(l.getBBox());
  });
  for (var _i = 0, bboxes_1 = bboxes; _i < bboxes_1.length; _i++) {
    var bbox = bboxes_1[_i];
    activeRegion.minX = (bbox.minX < activeRegion.minX ? bbox.minX : activeRegion.minX) - offset;
    activeRegion.minY = (bbox.minY < activeRegion.minY ? bbox.minY : activeRegion.minY) - offset;
    activeRegion.maxX = (bbox.maxX > activeRegion.maxX ? bbox.maxX : activeRegion.maxX) + offset;
    activeRegion.maxY = (bbox.maxY > activeRegion.maxY ? bbox.maxY : activeRegion.maxY) + offset;
  }
  activeRegion.width = activeRegion.maxX - activeRegion.minX;
  activeRegion.height = activeRegion.maxY - activeRegion.minY;
  activeRegion.x = activeRegion.minX;
  activeRegion.y = activeRegion.minY;
  return activeRegion;
}
function fillPotentialArea(members, nonMembers, edges, activeRegion, potentialArea, options) {
  function pos2GridIx(x, offset) {
    var gridIx = Math.floor((x - offset) / options.pixelGroupSize);
    return gridIx < 0 ? 0 : gridIx;
  }
  function gridIx2Pos(x, offset) {
    return x * options.pixelGroupSize + offset;
  }
  // using inverse a for numerical stability
  var nodeInfA = (options.nodeR0 - options.nodeR1) * (options.nodeR0 - options.nodeR1);
  var edgeInfA = (options.edgeR0 - options.edgeR1) * (options.edgeR0 - options.edgeR1);
  var getAffectedRegion = function getAffectedRegion(bbox, thresholdR) {
    var startX = Math.min(pos2GridIx(bbox.minX, thresholdR + activeRegion.minX), potentialArea.width);
    var startY = Math.min(pos2GridIx(bbox.minY, thresholdR + activeRegion.minY), potentialArea.height);
    var endX = Math.min(pos2GridIx(bbox.maxX, -thresholdR + activeRegion.minX), potentialArea.width);
    var endY = Math.min(pos2GridIx(bbox.maxY, -thresholdR + activeRegion.minY), potentialArea.height);
    return [startX, startY, endX, endY];
  };
  var addItemInfluence = function addItemInfluence(item, influenceFactor) {
    var bbox = item.getBBox();
    var _a = getAffectedRegion(bbox, options.nodeR1),
      startX = _a[0],
      startY = _a[1],
      endX = _a[2],
      endY = _a[3];
    // calculate item influence for each cell
    for (var y = startY; y < endY; y += 1) {
      for (var x = startX; x < endX; x += 1) {
        if (influenceFactor < 0 && potentialArea[x + y * potentialArea.width] <= 0) {
          continue;
        }
        var tempX = gridIx2Pos(x, activeRegion.minX);
        var tempY = gridIx2Pos(y, activeRegion.minY);
        var distanceSq = pointRectSquareDist({
          x: tempX,
          y: tempY
        }, {
          x: bbox.minX,
          y: bbox.minY,
          width: bbox.width,
          height: bbox.height
        });
        if (distanceSq < Math.pow(options.nodeR1, 2)) {
          var dr = Math.sqrt(distanceSq) - options.nodeR1;
          potentialArea.cells[x + y * potentialArea.width] += influenceFactor * dr * dr;
        }
      }
    }
  };
  var addEdgeInfluence = function addEdgeInfluence(line, influenceFactor) {
    var bbox = line.getBBox();
    var _a = getAffectedRegion(bbox, options.edgeR1),
      startX = _a[0],
      startY = _a[1],
      endX = _a[2],
      endY = _a[3];
    // for every point in active part of potentialArea, calculate distance to nearest point on line and add influence
    for (var y = startY; y < endY; y += 1) {
      for (var x = startX; x < endX; x += 1) {
        if (influenceFactor < 0 && potentialArea.cells[x + y * potentialArea.width] <= 0) {
          continue;
        }
        var tempX = gridIx2Pos(x, activeRegion.minX);
        var tempY = gridIx2Pos(y, activeRegion.minY);
        var minDistanceSq = pointLineSquareDist({
          x: tempX,
          y: tempY
        }, line);
        // only influence if less than r1
        if (minDistanceSq < Math.pow(options.edgeR1, 2)) {
          var mdr = Math.sqrt(minDistanceSq) - options.edgeR1;
          potentialArea.cells[x + y * potentialArea.width] += influenceFactor * mdr * mdr;
        }
      }
    }
  };
  if (options.nodeInfluenceFactor) {
    members.forEach(function (item) {
      addItemInfluence(item, options.nodeInfluenceFactor / nodeInfA);
    });
  }
  if (options.edgeInfluenceFactor) {
    edges.forEach(function (edge) {
      addEdgeInfluence(edge, options.edgeInfluenceFactor / edgeInfA);
    });
  }
  if (options.negativeNodeInfluenceFactor) {
    nonMembers.forEach(function (item) {
      addItemInfluence(item, options.negativeNodeInfluenceFactor / nodeInfA);
    });
  }
}
function rerouteLine(item, buffer, intersections, wrapNormal) {
  var bbox = item.getBBox();
  var topIntersect = intersections[0],
    leftIntersect = intersections[1],
    bottomIntersect = intersections[2],
    rightIntersect = intersections[3];
  var cornerPos = {
    topLeft: {
      x: bbox.minX - buffer,
      y: bbox.minY - buffer
    },
    topRight: {
      x: bbox.maxX + buffer,
      y: bbox.minY - buffer
    },
    bottomLeft: {
      x: bbox.minX - buffer,
      y: bbox.maxY + buffer
    },
    bottomRight: {
      x: bbox.maxX + buffer,
      y: bbox.maxY + buffer
    }
  };
  var totalArea = bbox.height * bbox.width;
  function calcHalfArea(intersect1, intersect2) {
    return bbox.width * ((intersect1.y - bbox.minY + (intersect2.y - bbox.minY)) * 0.5);
  }
  // 根据线和boundingbox相交的情况，确定control point的位置
  if (leftIntersect) {
    // 相交区域有三角形
    if (topIntersect) return wrapNormal ? cornerPos.topLeft : cornerPos.bottomRight;
    if (bottomIntersect) return wrapNormal ? cornerPos.bottomLeft : cornerPos.topRight;
    // 相交区域分成上下两个梯形，比较面积
    var topArea = calcHalfArea(leftIntersect, rightIntersect);
    if (topArea < totalArea * 0.5) {
      if (leftIntersect.y > rightIntersect.y) return wrapNormal ? cornerPos.topLeft : cornerPos.bottomRight;
      return wrapNormal ? cornerPos.topRight : cornerPos.bottomLeft;
    }
    if (leftIntersect.y < rightIntersect.y) return wrapNormal ? cornerPos.bottomLeft : cornerPos.topRight;
    return wrapNormal ? cornerPos.bottomRight : cornerPos.topLeft;
  }
  if (rightIntersect) {
    if (topIntersect) return wrapNormal ? cornerPos.topRight : cornerPos.bottomLeft;
    if (bottomIntersect) return wrapNormal ? cornerPos.bottomRight : cornerPos.topLeft;
  }
  // 相交区域分成左右两个梯形
  var leftArea = calcHalfArea(topIntersect, bottomIntersect);
  if (leftArea < totalArea * 0.5) {
    if (topIntersect.x > bottomIntersect.x) return wrapNormal ? cornerPos.topLeft : cornerPos.bottomRight;
    return wrapNormal ? cornerPos.bottomLeft : cornerPos.topRight;
  }
  if (topIntersect.x < bottomIntersect.x) return wrapNormal ? cornerPos.topRight : cornerPos.bottomLeft;
  return wrapNormal ? cornerPos.bottomRight : cornerPos.topLeft;
}